import { type AsyncLoaders, dataKeysAsync, dataKeysSync, type GaContextDataAsync } from "@utils/vue-migration/common/gaContext/gaContext";
import { delay } from "@utils/asyncUtils";
import { objectEntries } from "@utils/objectUtils";
import { assert } from "@utils/assertion";
import { dictToList } from "@utils/dictUtils";
// eslint-disable-next-line no-restricted-imports
import type { GaContextDataSync } from "@newgenerated/shared/schema";

/*
 * Do not use these outside of this folder!!!
 */

type InternalDataAsync<T> =
  | {
      state: "AVAILABLE";
      value: T;
      loader: () => Promise<T>;
    }
  | {
      state: "MISSING" | "LOADING";
      loader: () => Promise<T>;
    }
  | {
      state: "UNINITIALIZED";
    };

type InternalDataSync<T> =
  | {
      state: "AVAILABLE";
      value: T;
    }
  | {
      state: "UNINITIALIZED";
    };

export type InternalData = {
  sync: {
    [Property in keyof GaContextDataSync]: InternalDataSync<GaContextDataSync[Property]>;
  };
  async: {
    [Property in keyof GaContextDataAsync]: InternalDataAsync<GaContextDataAsync[Property]>;
  };
};

function initialInternalData(): InternalData {
  return {
    sync: {
      currentLanguage: {
        state: "UNINITIALIZED",
      },
      flags: {
        state: "UNINITIALIZED",
      },
    },
    async: {
      roleResourceConstraints: {
        state: "UNINITIALIZED",
      },
      en: {
        state: "UNINITIALIZED",
      },
      de: {
        state: "UNINITIALIZED",
      },
      es: {
        state: "UNINITIALIZED",
      },
      ru: {
        state: "UNINITIALIZED",
      },
      zh: {
        state: "UNINITIALIZED",
      },
      pt: {
        state: "UNINITIALIZED",
      },
      fr: {
        state: "UNINITIALIZED",
      },
    },
  };
}

export const internalData = initialInternalData();

/**
 * Should this setup function be called multiple times, only the first time the internal data will be set up. As it is possible that already some requests have been made, which we could no longer track, if the internal data would be overwritten.
 */
export function setupInternalData(syncData: GaContextDataSync, asyncLoaders: AsyncLoaders): void {
  dictToList(syncData).forEach(([key, _]) => {
    assert(dataKeysSync.includes(key as keyof GaContextDataSync), `Key '${key}' needs to be added to 'dataKeysSync'.`);
  });

  internalData.sync = objectEntries(syncData, dataKeysSync).reduce((acc, [key, value]) => {
    const entry = internalData.sync[key];
    const alreadyInitialized = entry.state !== "UNINITIALIZED";
    if (alreadyInitialized) {
      return acc;
    }
    return {
      ...acc,
      [key]: {
        state: "AVAILABLE",
        value: value,
      } satisfies InternalDataSync<unknown>,
    };
  }, internalData.sync);

  dictToList(asyncLoaders).forEach(([key, _]) => {
    assert(dataKeysAsync.includes(key as keyof GaContextDataAsync), `Key '${key}' needs to be added to 'dataKeysAsync'.`);
  });

  internalData.async = objectEntries(asyncLoaders, dataKeysAsync).reduce((acc, [key, loader]) => {
    const entry = internalData.async[key];
    const alreadyInitialized = entry.state !== "UNINITIALIZED";
    if (alreadyInitialized) {
      return acc;
    }
    return {
      ...acc,
      [key]: {
        state: "MISSING",
        loader: loader,
      } satisfies InternalDataAsync<unknown>,
    };
  }, internalData.async);
}

/**
 * `gaContext` has been built with the goal to reduce redundant request for data (e.g. network requests).
 * This includes that the setup function can be run multiple times without losing track of what has already been loaded.
 * This reset is only used for testings purposes.
 */
export function resetInternalData(): void {
  internalData.sync = initialInternalData().sync;
  internalData.async = initialInternalData().async;
}

export async function loadAsyncData<K extends keyof GaContextDataAsync>(key: K): Promise<GaContextDataAsync[K]> {
  while (true) {
    const currentStatus = internalData.async[key];

    if (currentStatus.state === "AVAILABLE") {
      // Data has already been set up
      return currentStatus.value;
    }

    if (currentStatus.state === "MISSING") {
      // Run loader for the first time
      (internalData.async[key] as InternalDataAsync<unknown>) = {
        state: "LOADING",
        loader: currentStatus.loader,
      } satisfies InternalDataAsync<unknown>;

      const data = await currentStatus.loader();

      (internalData.async[key] as InternalDataAsync<unknown>) = {
        state: "AVAILABLE",
        value: data,
        loader: currentStatus.loader,
      } satisfies InternalDataAsync<unknown>;
      return data;
    }

    // There is a request for the question in process
    await delay(100);
  }
}

export function syncDataMissingMessage(dataName: string): string {
  return `'${dataName}' is not AVAILABLE. Possible reasons: 'setupGaContext()' has not been run before Vue instance was created; Or 'setupGaContext()' was run, but did not setup the '${dataName}' properly.`;
}

export function asyncDataMissingMessage(dataName: string): string {
  return `'${dataName}' is not AVAILABLE. This should not be possible as this data should not be accessible before loading it.`;
}
