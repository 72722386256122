import { ensureNonNull } from "@utils/assertion";
import { type MIABSummaryForm } from "@newgenerated/shared/schema";
import { serialize_MIABSummaryForm, deserialize_MIABSummaryForm } from "@newgenerated/shared/mapper";

export { type MIABSummaryForm } from "@newgenerated/shared/schema";
export const parseMIABSummaryForm = (data: unknown): MIABSummaryForm => {
  return ensureNonNull(deserialize_MIABSummaryForm(JSON.stringify(data)));
};
export const renderMIABSummaryForm = (data: MIABSummaryForm): any => {
  return JSON.parse(serialize_MIABSummaryForm(data));
};
