/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type WarmupV2EditForm, parseWarmupV2EditForm, renderWarmupV2EditForm } from '../model/warmupV2EditForm';
// @ts-ignore
import { type WarmupV2Submission, parseWarmupV2Submission, renderWarmupV2Submission } from '../model/warmupV2Submission';
// @ts-ignore
import { type WarmupV2ValidationResult, parseWarmupV2ValidationResult, renderWarmupV2ValidationResult } from '../model/warmupV2ValidationResult';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @param warmupV2Submission 
*/
export async function processWarmupV2EditForm(warmupV2Submission: WarmupV2Submission): Promise<WarmupV2ValidationResult> {
        return getHttpClient().request<WarmupV2ValidationResult>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/warmup/v2`,
            method: 'POST',
            data: renderWarmupV2Submission(warmupV2Submission)
        })
        .then(response => {
               return parseWarmupV2ValidationResult(response.data);
        });
}
/**
* 
* @param warmupV2EditForm 
*/
export async function validateWarmupV2EditForm(warmupV2EditForm: WarmupV2EditForm): Promise<WarmupV2ValidationResult> {
        return getHttpClient().request<WarmupV2ValidationResult>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/warmup/v2/validate`,
            method: 'POST',
            data: renderWarmupV2EditForm(warmupV2EditForm)
        })
        .then(response => {
               return parseWarmupV2ValidationResult(response.data);
        });
}

