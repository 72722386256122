import "./_instant-search.scss";
import { registerModule } from "@/Registry";
import { assert, ensureNonNull } from "@utils/assertion";
import { objectEntries } from "@utils/objectUtils";
import _debounce from "lodash/debounce";

import type { InstantSearchResultListView } from "@generated/model/instantSearchResultListView";
import { type InstantSearchResult, parseInstantSearchResult } from "@generated/model/instantSearchResult";
import { useI18n } from "@/i18n/i18nSetup";
import { Language } from "@utils/type/type";
import getCurrentLanguage = Language.getCurrentLanguage;

/*
 * Shorthand plugin for the instant-search component that implements the client-side logic to support live search in our navigation.
 *
 * To initialize, the following data-attribute is mandatory:
 * data-ga-instant-search-txt-server-error:	the error message to render if the search isn't available
 * data-ga-instant-search-txt-no-results:		the message to show if no results where found
 * data-ga-instant-search: the endpoint url
 *
 * Additionally, the following elements are required for the plugin to work:
 * <input data-ga-search-input	/>		The field to enter the search term
 * <div data-ga-search-results></div>	The container used to apply the search results
 * <a data-ga-search-button>					A clickable search link
 * <a data-ga-search-reset>						A reset link to clear the search
 * <a data-ga-search-close>						A button to close the search field
 */

const SUPPORTED_CONTENT_TYPES = ["CUSTOMPAGE", "ACTIONABLE", "SUMMARY", "CHANNEL"] as const;
export type ContentTypes = "SUMMARY" | "CHANNEL" | "CUSTOMPAGE" | "ACTIONABLE";

function collapseSearch(searchForm: HTMLFormElement): void {
  searchForm.classList.remove("instant-search--expanded", "instant-search--results");
}

function expandSearch(searchForm: HTMLFormElement): void {
  searchForm.classList.add("instant-search--expanded", "instant-search--results");
  searchForm.dispatchEvent(new CustomEvent("ga.instantsearch.expanded", { bubbles: true }));
}

function isArrowKey(key: string): boolean {
  return key === "ArrowDown" || key === "ArrowUp";
}

async function search(query: string, searchApiUrl: string): Promise<InstantSearchResult | Error> {
  const searchUrl = new URL(searchApiUrl, location.href);
  searchUrl.searchParams.set("query", query);
  const response = await fetch(searchUrl);
  if (!response.ok) {
    return new Error(response.statusText);
  }

  return parseInstantSearchResult(await response.json());
}

function renderTitle(title: string): string {
  return `<li class="instant-search__results-group">${title}</li>`;
}

function wrapResult(results: string): string {
  return `<ul class="instant-search__results">${results}</ul>`;
}

function moveFocus(resultContainer: HTMLDivElement, searchField: HTMLInputElement, currentElement: HTMLElement, direction: string): void {
  const resultLinks = resultContainer.querySelectorAll<HTMLElement>(".instant-search__item-link");
  const currentIndex = Array.from(resultLinks).indexOf(currentElement);

  if (currentIndex < resultLinks.length - 1 && direction === "ArrowDown") {
    resultLinks.item(currentIndex + 1).focus();
  } else if (currentIndex > 0 && direction === "ArrowUp") {
    resultLinks.item(currentIndex - 1).focus();
  } else if (currentIndex === 0 && direction === "ArrowUp") {
    searchField.focus();
  }
}

export async function initInstantSearch(searchForm: Element): Promise<void> {
  assert(searchForm instanceof HTMLFormElement);
  const { t } = await useI18n(Language.getCurrentLanguage());

  let lastSearchTerm = "";

  const searchApiUrl = ensureNonNull(searchForm.dataset.gaInstantSearch);
  const txtPreview = searchForm.dataset.gaInstantSearchTxtPreview ?? "";
  const txtNoResult = searchForm.dataset.gaInstantSearchTxtNoResults ?? "";
  const txtServerError = searchForm.dataset.gaInstantSearchTxtServerError ?? "";
  const searchField = searchForm.querySelector<HTMLInputElement>("[data-ga-search-input]");
  const searchResultContainer = searchForm.querySelector<HTMLDivElement>("[data-ga-search-results]");
  const searchButton = searchForm.querySelector<HTMLButtonElement>("[data-ga-search-button]");
  const resetButton = searchForm.querySelector<HTMLButtonElement>("[data-ga-search-reset]");
  const closeButton = searchForm.querySelector<HTMLButtonElement>("[data-ga-search-close]");
  const placeholder = searchForm.querySelector<HTMLAnchorElement>("[data-ga-search-placeholder]");

  if (searchField == null || searchResultContainer == null || searchButton == null || resetButton == null || closeButton == null) {
    throw Error("Invalid search state");
  }

  if (placeholder != null) {
    placeholder.addEventListener("click", () => {
      expandSearch(searchForm);
      searchField.focus();
    });
  }

  if (/\/[a-z]{2}\/search/.test(window.location.pathname)) {
    const urlParams = new URLSearchParams(window.location.search);
    const query = urlParams.get("query");
    if (query !== null) {
      searchField.value = query;
    }
  }
  resetButton.addEventListener("click", function () {
    searchForm.reset();
  });

  searchForm.addEventListener("reset", function () {
    searchForm.classList.remove("instant-search--active");
    searchResultContainer.innerHTML = "";
    lastSearchTerm = "";
    collapseSearch(searchForm);
  });

  document.addEventListener("mouseup", (e) => {
    const target = ensureNonNull(e.target);
    if (target instanceof Node && (!searchForm.contains(target) || closeButton.contains(target))) {
      collapseSearch(searchForm);
    }
  });

  searchField.addEventListener("keyup", function (e) {
    if (isArrowKey(e.key)) {
      // For up/down arrow keys, don't trigger search, but move focus
      moveFocus(searchResultContainer, searchField, this, e.key);
      return;
    }
  });

  function renderRow(e: InstantSearchResultListView): string {
    return `
      <li class="instant-search__item instant-search__item--${e.contentItemType.toLowerCase()} ${e.activationTime === null ? " instant-search__item--inactive" : ""}">
        <a href="${e.url}" class="instant-search__item-link">
          <img width="40" height="60" src="${e.thumbnail}" alt="" class="instant-search__item-cover instant-search__item-cover--${e.contentItemType.toLowerCase()}">
          <span class="instant-search__item-biblio">
            <span class="instant-search__item-title">${e.title}</span>
            <span class="instant-search__item-author">${e.authorInfo ?? ""}</span>
          </span>
          ${
            e.activationTime === null
              ? `<span class="ms-2">
                  <span class="badge badge-primary-dark">${txtPreview}</span>
                </span>`
              : ""
          }
        </a>
      </li>`;
  }

  const sortPosition: { [content in ContentTypes]: number } = {
    SUMMARY: 1,
    ACTIONABLE: 2,
    CHANNEL: 3,
    CUSTOMPAGE: 4,
  };

  function processResults(results: InstantSearchResult, noResultTxt: string, search: HTMLFormElement, searchTerm: string): string {
    const filteredResults = objectEntries(results, SUPPORTED_CONTENT_TYPES)
      .filter(([_key, value]) => value !== null)
      .sort(([firstKey], [secondKey]) => sortPosition[firstKey] - sortPosition[secondKey]);
    const renderedResults = filteredResults.map(function ([key, value]) {
      assert(value !== null);
      const renderedRows = value.map(function (r) {
        return renderRow(r);
      });
      const messageKey = key.charAt(0) + key.toLowerCase().slice(1);
      const renderedTitle = renderedRows.length > 0 ? renderTitle(ensureNonNull(search.dataset["gaInstantSearch" + messageKey])) : " ";
      let maxResult = 3;
      if (key === "CUSTOMPAGE" || (filteredResults.length === 1 && key === "SUMMARY")) {
        maxResult = 5;
      } else if (key === "ACTIONABLE") {
        maxResult = 2;
      } else if (key === "CHANNEL") {
        maxResult = 1;
      }
      const result = renderedRows.slice(0, maxResult);
      result.unshift(renderedTitle);
      return result.join("\r\n");
    });

    if (renderedResults.length > 0) {
      const showAll = `<div class="instant-search__allResults"><a class="btn btn-alt-primary btn-sm" href="/${getCurrentLanguage()}/search?query=${encodeURIComponent(searchTerm)}">${t("search:instantsearch.allResults")}</a></div>`;
      return wrapResult(renderedResults.join("\r\n") + showAll);
    }
    return wrapResult('<li class="instant-search__results-message">' + noResultTxt + "</li>");
  }

  searchField.addEventListener(
    "keyup",
    _debounce(async () => {
      if (searchField.value === "" && lastSearchTerm !== searchField.value) {
        searchForm.reset();
      } else if (lastSearchTerm !== searchField.value) {
        const currentSearchTerm = searchField.value;
        searchForm.classList.add("instant-search--loading", "instant-search--active", "instant-search--results");
        const result = await search(searchField.value, searchApiUrl);
        if (currentSearchTerm === searchField.value) {
          if (result instanceof Error) {
            searchResultContainer.innerHTML = wrapResult('<li class="instant-search__results-message">' + txtServerError + "</li>");
          } else if (searchForm.classList.contains("instant-search--active")) {
            searchResultContainer.innerHTML = processResults(result, txtNoResult, searchForm, searchField.value);
          }
          searchForm.classList.remove("instant-search--loading");
          lastSearchTerm = searchField.value;
        }
      }
    }, 300),
  );

  function renderSuggestion(suggestion: string, aiSuggestion: boolean): string {
    const baseUri = aiSuggestion ? "/ask-getabstract?question=" : "/search?query=";
    return `
    <li class="instant-search__suggestion-item">
      <a class="instant-search__item-link" href="${encodeURI(baseUri + suggestion)}">
        <span class="${aiSuggestion ? "ico-sparks-2" : "ico-graph"}"></span>
        <span class="instant-search__item-biblio instant-search__item-suggestion">
          <h5 class="mb-0">${suggestion}</h5>
        </span>
      </a>
    </li>`;
  }

  searchField.addEventListener("focusin", function () {
    if (this.value === "") {
      void fetch("/instantsearch/suggestions")
        .then((r) => r.json())
        .then((results) => {
          let rows = results.map((suggestion: string) => {
            return renderSuggestion(suggestion, false);
          });
          if (rows.length > 0) {
            rows = renderTitle(t("search:trending")) + rows.join("\r\n");
            searchResultContainer.innerHTML = wrapResult(rows);
          }
          rows = rows + renderTitle(t("search:suggestions.deepDive")) + renderSuggestion(t("search:suggestions.deepDive.question1"), true) + renderSuggestion(t("search:suggestions.deepDive.question2"), true);
          searchResultContainer.innerHTML = wrapResult(rows);
        });
    }
    if (!searchForm.classList.contains("instant-search--expanded")) {
      expandSearch(searchForm);
    }
  });

  searchForm.addEventListener("focusout", function (e) {
    if (e.target instanceof Node && !searchForm.contains(e.target)) {
      collapseSearch(searchForm);
    }
  });

  searchButton.addEventListener("click", function (e) {
    e.preventDefault();
    if (searchForm.classList.contains("instant-search--expanded") && searchField.value !== "") {
      searchForm.submit();
    } else {
      searchField.focus();
    }
  });

  searchResultContainer.addEventListener("keydown", function (e) {
    if (e.target instanceof HTMLAnchorElement && (e.key === "ArrowUp" || e.key === "ArrowDown")) {
      // For up/down arrow keys, move focus

      moveFocus(searchResultContainer, searchField, e.target, e.key);
      e.preventDefault();
      e.stopPropagation();
    }
  });
}

registerModule("[data-ga-instant-search]", initInstantSearch);
