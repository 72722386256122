import { ensureNonNull } from "@utils/assertion";
import { type MIABSummaryResults } from "@newgenerated/shared/schema";
import { serialize_MIABSummaryResults, deserialize_MIABSummaryResults } from "@newgenerated/shared/mapper";

export { type MIABSummaryResults } from "@newgenerated/shared/schema";
export const parseMIABSummaryResults = (data: unknown): MIABSummaryResults => {
  return ensureNonNull(deserialize_MIABSummaryResults(JSON.stringify(data)));
};
export const renderMIABSummaryResults = (data: MIABSummaryResults): any => {
  return JSON.parse(serialize_MIABSummaryResults(data));
};
