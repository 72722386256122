import { ensureNonNull } from "@utils/assertion";
import { type AskGetabstractProperties } from "@newgenerated/shared/schema";
import { serialize_AskGetabstractProperties, deserialize_AskGetabstractProperties } from "@newgenerated/shared/mapper";

export { type AskGetabstractProperties } from "@newgenerated/shared/schema";
export const parseAskGetabstractProperties = (data: unknown): AskGetabstractProperties => {
  return ensureNonNull(deserialize_AskGetabstractProperties(JSON.stringify(data)));
};
export const renderAskGetabstractProperties = (data: AskGetabstractProperties): any => {
  return JSON.parse(serialize_AskGetabstractProperties(data));
};
