import type { GaVueComponent } from "@/common/vueUtils";

export function LoadingComponent(props: { itemHeight: string; itemsPerRow: 2 | 3; withTitle?: boolean }): GaVueComponent {
  const showTitle = props.withTitle ?? false;
  const gridClasses = props.itemsPerRow === 2 ? "row row-cols-1 row-cols-md-2 row-gap-3" : "row row-cols-1 row-cols-md-2 row-cols-lg-3 row-gap-3";
  const height = "height:" + props.itemHeight + ";";
  return (
    <div class="placeholder-wave mb-5">
      {showTitle ? (
        <div>
          <span class="placeholder col-3 mb-4" style="height:3.625rem"></span>
        </div>
      ) : null}
      <div class={gridClasses}>
        <div class="col">
          <span class="placeholder fullSearch__placeholder" style={height}></span>
        </div>
        <div class="col">
          <span class="placeholder fullSearch__placeholder" style={height}></span>
        </div>
        <div class="col">
          <span class="placeholder fullSearch__placeholder" style={height}></span>
        </div>
      </div>
    </div>
  );
}
