import { ensureNonNull } from "@utils/assertion";
import { type SummaryCardDemoProps } from "@newgenerated/shared/schema";
import { serialize_SummaryCardDemoProps, deserialize_SummaryCardDemoProps } from "@newgenerated/shared/mapper";

export { type SummaryCardDemoProps } from "@newgenerated/shared/schema";
export const parseSummaryCardDemoProps = (data: unknown): SummaryCardDemoProps => {
  return ensureNonNull(deserialize_SummaryCardDemoProps(JSON.stringify(data)));
};
export const renderSummaryCardDemoProps = (data: SummaryCardDemoProps): any => {
  return JSON.parse(serialize_SummaryCardDemoProps(data));
};
