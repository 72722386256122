import type { Store } from "@/common/storeUtils";
import type { ContentTypeProps, SearchProps } from "@/components/search/fullSearchStoreTypes";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { SummaryCard } from "@/components/cards/SummaryCard";
import { GaButton } from "@/components/general/GaButton";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import ChannelCardMini from "@utils/vue-migration/component/channel/ChannelCardMini.vue";
import CustomPageCardMini from "@utils/vue-migration/component/custompage/CustomPageCardMini.vue";

function loadMore(page: Store<number>, search: () => void): void {
  page.set(page.get() + 1);
  search();
}

export function ContentTypeChildren(props: { contentType: Store<ContentTypeProps>; search: () => void; searchProps: Store<SearchProps> }): GaVueComponent {
  const contentTypeProps = props.contentType.unpackUnion();
  const selected = props.searchProps.sub("selectedContentType").get() === props.contentType.get().kind;
  const category = contentTypeProps.store.get();
  const amountToShow = selected ? category.items.length : contentTypeProps.store.get().amountToShow;
  const standardColumns = "row row-cols-1 row-cols-md-2 g-4 ";
  const bigColumn = standardColumns + (selected ? "row-cols-lg-3" : "");
  const smallColumn = standardColumns + (selected ? "row-cols-lg-4" : "row-cols-lg-3");
  const { t } = useI18n();
  switch (contentTypeProps.kind) {
    case "SUMMARY":
      return (
        <>
          <div class={bigColumn}>
            {contentTypeProps.store
              .get()
              .items.slice(0, amountToShow)
              .map((result, i) => {
                const params = new URLSearchParams(props.searchProps.sub("params").get() as URLSearchParams);
                params.append("o_p", String(i));
                return (
                  <div class="col" key={i}>
                    <SummaryCard
                      summary={{
                        ...result,
                        authors: [...result.authors],
                        countriesIncluded: [...result.countriesIncluded],
                        countriesExcluded: [...result.countriesExcluded],
                        tags: [...result.tags],
                      }}
                      params={params}
                    />
                  </div>
                );
              })}
          </div>
          {props.contentType.sub("paging").sub("totalCount").get() > contentTypeProps.store.sub("items").sub("length").get() && selected ? (
            <div class="d-flex justify-content-center mt-3">
              <GaButton onClick={() => loadMore(props.contentType.sub("paging").sub("page"), props.search)}>
                {props.searchProps.sub("status").get() === "LOADMORE" ? <div class="spinner-border spinner-border-sm" /> : t("browse:explore.loadMore")}
              </GaButton>
            </div>
          ) : null}
        </>
      );
    case "CHANNEL":
      return (
        <>
          <div class={bigColumn}>
            {contentTypeProps.store
              .get()
              .items.slice(0, amountToShow)
              .map((result, i) => {
                const params = new URLSearchParams(props.searchProps.sub("params").get() as URLSearchParams);
                params.append("o_p", String(i));
                return (
                  <div class="col" key={i}>
                    <ChannelCardMini channel={{ ...result, visibleLanguages: [...result.visibleLanguages] }} params={params} />
                  </div>
                );
              })}
          </div>
          {props.contentType.sub("paging").sub("totalCount").get() > contentTypeProps.store.sub("items").sub("length").get() && selected ? (
            <div class="d-flex justify-content-center mt-3">
              <GaButton onClick={() => loadMore(props.contentType.sub("paging").sub("page"), props.search)}>
                {props.searchProps.sub("status").get() === "LOADMORE" ? <div class="spinner-border spinner-border-sm" /> : t("browse:explore.loadMore")}
              </GaButton>
            </div>
          ) : null}
        </>
      );
    case "CUSTOMPAGE":
      return (
        <div class={smallColumn}>
          {contentTypeProps.store
            .get()
            .items.slice(0, amountToShow)
            .map((result, i) => {
              const params = new URLSearchParams(props.searchProps.sub("params").get() as URLSearchParams);
              params.append("o_p", String(i));
              return (
                <div class="col" key={i}>
                  <CustomPageCardMini customPage={result} params={params} />
                </div>
              );
            })}
        </div>
      );
    case "ACTIONABLE":
      return (
        <div class={bigColumn}>
          {contentTypeProps.store
            .get()
            .items.slice(0, amountToShow)
            .map((result, i) => {
              const params = new URLSearchParams(props.searchProps.sub("params").get() as URLSearchParams);
              params.append("o_p", String(i));
              return (
                <div class="col" key={i}>
                  <ActionableCardMini actionable={{ actionableId: BigInt(result.actionableId), name: result.name, title: result.title, coverUri: result.coverUri }} params={params} />
                </div>
              );
            })}
        </div>
      );
  }
}
