/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type RoleResourceConstraint, parseRoleResourceConstraint, renderRoleResourceConstraint } from '../model/roleResourceConstraint';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* Endpoint to check to which tools/resources the currently logged in user has access to.
*/
export async function getResourceConstraints(): Promise<Array<RoleResourceConstraint>> {
        return getHttpClient().request<Array<RoleResourceConstraint>>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/resources`,
            method: 'GET'
        })
        .then(response => {
                return response.data.map(value => parseRoleResourceConstraint(value));
        });
}

