import { ensureNonNull } from "@utils/assertion";
import { type RoleResourceConstraint } from "@newgenerated/shared/schema";
import { serialize_RoleResourceConstraint, deserialize_RoleResourceConstraint } from "@newgenerated/shared/mapper";

export { type RoleResourceConstraint } from "@newgenerated/shared/schema";
export const parseRoleResourceConstraint = (data: unknown): RoleResourceConstraint => {
  return ensureNonNull(deserialize_RoleResourceConstraint(JSON.stringify(data)));
};
export const renderRoleResourceConstraint = (data: RoleResourceConstraint): any => {
  return JSON.parse(serialize_RoleResourceConstraint(data));
};
