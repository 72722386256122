import { ensureNonNull } from "@utils/assertion";
import { type FullSearchProps } from "@newgenerated/shared/schema";
import { serialize_FullSearchProps, deserialize_FullSearchProps } from "@newgenerated/shared/mapper";

export { type FullSearchProps } from "@newgenerated/shared/schema";
export const parseFullSearchProps = (data: unknown): FullSearchProps => {
  return ensureNonNull(deserialize_FullSearchProps(JSON.stringify(data)));
};
export const renderFullSearchProps = (data: FullSearchProps): any => {
  return JSON.parse(serialize_FullSearchProps(data));
};
