import { ensureNonNull } from "@utils/assertion";
import { type GaContextDataSync } from "@newgenerated/shared/schema";
import { serialize_GaContextDataSync, deserialize_GaContextDataSync } from "@newgenerated/shared/mapper";

export { type GaContextDataSync } from "@newgenerated/shared/schema";
export const parseGaContextDataSync = (data: unknown): GaContextDataSync => {
  return ensureNonNull(deserialize_GaContextDataSync(JSON.stringify(data)));
};
export const renderGaContextDataSync = (data: GaContextDataSync): any => {
  return JSON.parse(serialize_GaContextDataSync(data));
};
