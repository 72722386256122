import { type DeepReadonly, defineComponent, type PropType } from "vue";
import { createInitialSearchStore, executeSearch } from "@/components/search/fullSearchStore";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import "./fullSearch.scss";
import { GaChip } from "@/components/general/GaChip";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { FullSearchProps } from "@newgenerated/shared/schema";
import { SearchAskGetAbstractComponent } from "@/components/search/SearchAskGetAbstractComponent";
import { SearchResults } from "@/components/search/SearchResults";
import type { ContentTypeProps, ExtendedContentType, SearchStoreState } from "@/components/search/fullSearchStoreTypes";
import type { Store } from "@/common/storeUtils";
import { search } from "@generated/api/searchApiControllerApi";

function SearchContentTabs(props: { selectedContentType: Store<ExtendedContentType>; contentTypes: DeepReadonly<ContentTypeProps[]>; loading: boolean; hasCorporatePlus: boolean }): GaVueComponent {
  const tabOrder: { [content in ContentTypes]: number } = {
    SUMMARY: 1,
    ACTIONABLE: 2,
    CHANNEL: 3,
    CUSTOMPAGE: 4,
  };
  const { t } = useI18n();

  return (
    <div class="d-flex gap-3 flex-wrap">
      <GaChip active={props.selectedContentType.get() === "ALL"} changeHandler={() => props.selectedContentType.set("ALL")} value="ALL" type="radio" name="contentTypeTabs">
        {t("search:page.results.topResults")}
      </GaChip>
      {[...props.contentTypes]
        .sort((a, b) => tabOrder[a.kind] - tabOrder[b.kind])
        .filter((contentType) => contentType.items.length > 0 || contentType.filterProps.formFilters.length > 0)
        .filter((contentType) => contentType.kind !== "ACTIONABLE" || props.hasCorporatePlus)
        .map((contentType) => (
          <span>
            <GaChip changeHandler={() => props.selectedContentType.set(contentType.kind)} active={contentType.kind === props.selectedContentType.get()} value={contentType.kind} type="radio" name="contentTypeTabs">
              {contentType.title}
            </GaChip>
          </span>
        ))}
    </div>
  );
}

function getBreakPointMdValue(): number {
  const breakPointValue = getComputedStyle(document.body).getPropertyValue("--ga-breakpoint-lg");
  return parseInt(breakPointValue, 10);
}

function Content(props: { store: Store<SearchStoreState>; search: (searchTerm: string, softReload: boolean) => void; hasCorporatePlus: boolean }): GaVueComponent {
  const { t } = useI18n();
  const smallVersion = getBreakPointMdValue() >= window.innerWidth;
  const aiState = props.store.sub("aiState").unpackUnion();
  const useFullWidth = props.store.sub("searchProps").sub("selectedContentType").get() !== "ALL" || aiState.kind === "NOT_AVAILABLE";

  return (
    <div class="row mt-4 gx-4">
      <h1 class="mb-4">{t("search:page.title")}</h1>
      <div class="mb-5">
        <SearchContentTabs
          selectedContentType={props.store.sub("searchProps").sub("selectedContentType")}
          contentTypes={props.store.sub("contentTypeProps").get()}
          loading={props.store.sub("searchProps").sub("status").get() === "LOADING"}
          hasCorporatePlus={props.hasCorporatePlus}
        />
      </div>
      <div class={["col-12 order-1 order-lg-0", useFullWidth ? "" : "col-lg-8"]}>
        <SearchResults searchProps={props.store.sub("searchProps")} contentTypeProps={props.store.sub("contentTypeProps")} search={props.search} />
      </div>
      {aiState.kind === "AVAILABLE" ? (
        <div class={["col-12 order-0 order-lg-1 mb-5", props.store.sub("searchProps").sub("selectedContentType").get() === "ALL" ? "col-lg-4" : "d-none"]}>
          <SearchAskGetAbstractComponent state={aiState.store.get().uiState} displayFullAnswer={aiState.store.get().displayFullAnswer} showMore={() => aiState.store.sub("displayFullAnswer").set(true)} smallVersion={smallVersion} />
        </div>
      ) : null}
    </div>
  );
}

export const FullSearch = defineComponent({
  props: {
    fullSearchProps: {
      type: Object as PropType<FullSearchProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createInitialSearchStore(props.fullSearchProps);
    if (props.fullSearchProps.query !== null && props.fullSearchProps.query !== "") {
      void executeSearch(store.sub("searchProps"), store.sub("contentTypeProps"), props.fullSearchProps.query, search);
    }
    return () => (
      <Content
        store={store}
        search={(searchTerm: string, softReload: boolean) => executeSearch(store.sub("searchProps"), store.sub("contentTypeProps"), searchTerm, search, softReload)}
        hasCorporatePlus={props.fullSearchProps.hasCorporatePlus}
      />
    );
  },
});
