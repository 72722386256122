import { ensureNonNull } from "@utils/assertion";
import { type ActionablePageProps } from "@newgenerated/shared/schema";
import { serialize_ActionablePageProps, deserialize_ActionablePageProps } from "@newgenerated/shared/mapper";

export { type ActionablePageProps } from "@newgenerated/shared/schema";
export const parseActionablePageProps = (data: unknown): ActionablePageProps => {
  return ensureNonNull(deserialize_ActionablePageProps(JSON.stringify(data)));
};
export const renderActionablePageProps = (data: ActionablePageProps): any => {
  return JSON.parse(serialize_ActionablePageProps(data));
};
