import type { SelectOption } from "@/components/form/GaFormFieldInputSelect";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaChip } from "@/components/general/GaChip";
import "./_gaFormFieldMultiSelect.scss";
import type { DeepReadonly } from "vue";
import { defineComponent, type PropType, ref } from "vue";
import { delay } from "@utils/asyncUtils";

export type MultiSelectStateProps<T> = {
  options: SelectOption<T>[];
  searchTerm: string;
  showSearch: boolean;
  help?: string;
  errors?: DeepReadonly<string[]>;
};

export type MultiSelectActions<T> = {
  onChange: (options: T[]) => void;
  onSearchTermChange: (value: string) => void;
  toggleSearch: () => void;
};

export type MultiSelectProps<T> = MultiSelectStateProps<T> &
  MultiSelectActions<T> & {
    selectedOptions: T[];
  };

function getSelectedItemsWithout<T>(optionToRemove: SelectOption<T>, options: T[]): T[] {
  return options.filter((option) => option !== optionToRemove.value);
}

const MultiSelectContent = defineComponent({
  props: {
    toggleSearch: {
      type: Function as PropType<() => void>,
      required: true,
    },
    options: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<SelectOption<any>[]>,
      required: true,
    },
    selectedOptions: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<any[]>,
      required: true,
    },
    onChange: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Function as PropType<(value: any[]) => void>,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
    onSearchTermChange: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    help: {
      type: String,
    },
    errors: {
      type: Array as PropType<DeepReadonly<string[]>>,
    },
  },
  setup: (props) => {
    const id = window.crypto.randomUUID().substring(0, 7);
    const input = ref<InstanceType<typeof HTMLInputElement> | undefined>(undefined);
    const errors = props.errors ?? [];

    async function togglePopup(event: MouseEvent): Promise<void> {
      event.stopPropagation();
      props.toggleSearch();
      if (!props.showSearch) {
        await delay(0);
        input.value?.focus();
        document.addEventListener("click", toggleHelperFunction);
      }
    }

    function toggleHelperFunction(event: MouseEvent): void {
      void togglePopup(event);
      document.removeEventListener("click", toggleHelperFunction);
    }

    return () => (
      <>
        <div
          class={{
            "ga-multiselect": true,
            "is-invalid": errors.length > 0,
          }}
          onClick={(event) => event.stopPropagation()}>
          <div
            class={{
              "form-control ga-multiselect__selected d-flex flex-wrap gap-1": true,
              "is-invalid": errors.length > 0,
            }}
            onClick={(event) => togglePopup(event)}>
            {props.options
              .filter((o) => props.selectedOptions.includes(o.value))
              .map((option) => (
                <GaChip type="removable" removeHandler={() => props.onChange(getSelectedItemsWithout(option, props.selectedOptions))}>
                  {option.label}
                </GaChip>
              ))}
          </div>
          <div class={["ga-multiselect__search", props.showSearch ? "show" : ""]} onBlur={() => console.log("serch blur")}>
            <input id={id} ref={input} class="form-control ga-multiselect__input" type="text" value={props.searchTerm} onInput={(e) => props.onSearchTermChange((e.target as HTMLInputElement).value)} />
            {props.options.filter((option) => !props.selectedOptions.includes(option)).filterLike("label", props.searchTerm).length > 0 ? (
              <ul class="ga-multiselect__search-list">
                {props.options
                  .filter((option) => props.selectedOptions.find((s) => s === option.value) === undefined)
                  .filterLike("label", props.searchTerm)
                  .map((option) => (
                    <li class="ga-multiselect__search-list-item" onClick={() => props.onChange([...props.selectedOptions, option.value])}>
                      {option.label}
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        </div>
        {errors.map((error) => (
          <div class="invalid-feedback">{error}</div>
        ))}
        {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
      </>
    );
  },
});

export function GaFormFieldMultiSelect<T>(props: MultiSelectProps<T>): GaVueComponent {
  return (
    <MultiSelectContent
      showSearch={props.showSearch}
      toggleSearch={props.toggleSearch}
      options={props.options}
      selectedOptions={props.selectedOptions}
      onChange={props.onChange}
      searchTerm={props.searchTerm}
      onSearchTermChange={props.onSearchTermChange}
      help={props.help}
      errors={props.errors}
    />
  );
}
