import { ensureNonNull } from "@utils/assertion";
import { type MIABOverviewPageProps } from "@newgenerated/shared/schema";
import { serialize_MIABOverviewPageProps, deserialize_MIABOverviewPageProps } from "@newgenerated/shared/mapper";

export { type MIABOverviewPageProps } from "@newgenerated/shared/schema";
export const parseMIABOverviewPageProps = (data: unknown): MIABOverviewPageProps => {
  return ensureNonNull(deserialize_MIABOverviewPageProps(JSON.stringify(data)));
};
export const renderMIABOverviewPageProps = (data: MIABOverviewPageProps): any => {
  return JSON.parse(serialize_MIABOverviewPageProps(data));
};
