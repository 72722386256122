import { type DeepReadonly, defineComponent, onMounted, type PropType } from "vue";
import type { MIABOverviewPageProps, SummaryMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { SummaryCard } from "@/components/cards/SummaryCard";
import { Language } from "@utils/type/type";
import type { ContentTypeFilterProps, FormFilterExtended } from "@/components/search/fullSearchStoreTypes";
import { createStore, type Store } from "@/common/storeUtils";
import { getPopularSummaries } from "@generated/api/mIABControllerApi";
import { convertToSummarySearchFilters, getMultiSelectProps } from "@/components/search/fullSearchStore";
import { LoadingComponent } from "@/components/search/LoadingComponent";
import { NoResults } from "@/components/search/NoResults";
import { GaButton } from "@/components/general/GaButton";

type LoadingState = {
  kind: "LOADING";
};

type ErrorState = {
  kind: "ERROR";
};

type IdleState = {
  kind: "IDLE";
  summaries: SummaryMetadata[];
};

type SoftFetchState = {
  kind: "SOFTFETCH";
  summaries: SummaryMetadata[];
};

type MIABOverviewPageState = (IdleState | LoadingState | ErrorState | SoftFetchState) & {
  filterProps: ContentTypeFilterProps;
  summaryPage: number;
};

async function fetchSummaries(store: Store<MIABOverviewPageState>, softFetch?: boolean): Promise<void> {
  const unpackedStore = store.unpackUnion();
  if (softFetch !== undefined && unpackedStore.kind === "IDLE") {
    store.set({
      ...unpackedStore.store.get(),
      kind: "SOFTFETCH",
    });
  } else {
    store.set({
      kind: "LOADING",
      filterProps: { ...store.get().filterProps },
      summaryPage: store.get().summaryPage,
    });
  }
  try {
    const result = await getPopularSummaries({ page: store.get().summaryPage, filters: convertToSummarySearchFilters(store.get().filterProps.formFilters) });
    store.set({
      kind: "IDLE",
      filterProps: { ...store.get().filterProps, formFilters: result.filters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })) },
      summaryPage: store.get().summaryPage,
      summaries: result.summaries,
    });
  } catch (_) {
    store.set({
      kind: "ERROR",
      filterProps: { ...unpackedStore.store.get().filterProps },
      summaryPage: unpackedStore.store.get().summaryPage,
    });
  }
}

async function resetAllFilters(filters: Store<FormFilterExtended[]>, store: Store<MIABOverviewPageState>): Promise<void> {
  const resettedFilters: DeepReadonly<FormFilterExtended[]> = filters.get().map((filter) => {
    return { ...filter, activeValues: [] };
  });
  filters.set(resettedFilters);
  await fetchSummaries(store);
}

async function loadMore(store: Store<MIABOverviewPageState>): Promise<void> {
  const state = store.get();
  store.sub("summaryPage").set(state.summaryPage + 1);
  await fetchSummaries(store, true);
}

function Error(): GaVueComponent {
  const { t } = useI18n();
  return (
    <div>
      <h2 class="h3">{t("miab:page.loadingError.title")}</h2>
      <p>{t("miab:page.loadingError.description")}</p>
    </div>
  );
}

function Content(props: { store: Store<MIABOverviewPageState> }): GaVueComponent {
  const { t } = useI18n();
  const unpackedStore = props.store.unpackUnion();
  return (
    <>
      <div class="mb-4 mt-4">
        <h1>{t("miab:overviewPage.title")}</h1>
        <p class="lead mb-5">{t("miab:overviewPage.description")}</p>
      </div>
      {unpackedStore.kind === "LOADING" ? (
        <LoadingComponent itemsPerRow={3} itemHeight={"12.8125rem"} />
      ) : unpackedStore.kind === "IDLE" || unpackedStore.kind === "SOFTFETCH" ? (
        <div class="row">
          {unpackedStore.store.get().summaries.length > 0 ? (
            <>
              {unpackedStore.store.get().summaries.map((miab) => (
                <div class="col col-lg-4 col-md-6 mb-3">
                  <SummaryCard summary={miab} link={new URL(`${location.origin}/${Language.getCurrentLanguage()}/miab/${miab.dataId}`)} />
                </div>
              ))}
              <div class="d-flex justify-content-center">
                <GaButton onClick={() => loadMore(props.store)}>
                  {t("miab:page.paging.loadMore")} {unpackedStore.kind === "SOFTFETCH" ? <div class="spinner-border spinner-border-sm"></div> : null}
                </GaButton>
              </div>
            </>
          ) : (
            <NoResults resetFilters={() => resetAllFilters(props.store.sub("filterProps").sub("formFilters"), props.store)} />
          )}
        </div>
      ) : (
        <Error />
      )}
    </>
  );
}

export const MIABOverviewPage = defineComponent({
  props: {
    miabOverviewPageProps: {
      type: Object as PropType<MIABOverviewPageProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore<MIABOverviewPageState>({
      kind: "LOADING",
      filterProps: {
        formFilters: props.miabOverviewPageProps.formFilters.map((filter) => ({ ...filter, isCollapsed: true, multiSelectProps: getMultiSelectProps(filter) })),
        formFilterExtended: false,
      },
      summaryPage: 0,
    });
    onMounted(async () => {
      await fetchSummaries(store);
    });
    return () => <Content store={store} />;
  },
});
