/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type OutboundLink, parseOutboundLink, renderOutboundLink } from '../model/outboundLink';
// @ts-ignore
import { type PageViewPayload, parsePageViewPayload, renderPageViewPayload } from '../model/pageViewPayload';
// @ts-ignore
import { type ShareEvent, parseShareEvent, renderShareEvent } from '../model/shareEvent';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @param outboundLink 
*/
export async function trackOutboundLinkClick(outboundLink: OutboundLink): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/act/olc`,
            method: 'POST',
            data: renderOutboundLink(outboundLink)
        });
}
/**
* 
* @param pageViewPayload 
*/
export async function trackPageView(pageViewPayload: PageViewPayload): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/act/pv`,
            method: 'POST',
            data: renderPageViewPayload(pageViewPayload)
        });
}
/**
* 
* @param shareEvent 
*/
export async function trackShareEvent(shareEvent: ShareEvent): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/analytics/track-share-event`,
            method: 'POST',
            data: renderShareEvent(shareEvent)
        });
}

