/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type AskGetabstractAnalyticsFeedbackEventForm, parseAskGetabstractAnalyticsFeedbackEventForm, renderAskGetabstractAnalyticsFeedbackEventForm } from '../model/askGetabstractAnalyticsFeedbackEventForm';
// @ts-ignore
import { type AskGetabstractQuestion, parseAskGetabstractQuestion, renderAskGetabstractQuestion } from '../model/askGetabstractQuestion';
// @ts-ignore
import { type AskGetabstractQuestionAnswers, parseAskGetabstractQuestionAnswers, renderAskGetabstractQuestionAnswers } from '../model/askGetabstractQuestionAnswers';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @param askGetabstractQuestion 
*/
export async function createQuestion(askGetabstractQuestion: AskGetabstractQuestion): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/search/ask-getabstract`,
            method: 'POST',
            data: renderAskGetabstractQuestion(askGetabstractQuestion)
        });
}
/**
* 
* @param questionUuid 
*/
export async function getQuestionAnswers(questionUuid: string): Promise<AskGetabstractQuestionAnswers> {
        return getHttpClient().request<AskGetabstractQuestionAnswers>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/search/ask-getabstract/questionAnswers/{questionUuid}`
                .replace('{' + 'questionUuid' + '}', encodeURIComponent(String(questionUuid))),
            method: 'GET'
        })
        .then(response => {
               return parseAskGetabstractQuestionAnswers(response.data);
        });
}
/**
* 
* @param askGetabstractAnalyticsFeedbackEventForm 
*/
export async function sendFeedback(askGetabstractAnalyticsFeedbackEventForm: AskGetabstractAnalyticsFeedbackEventForm): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/search/ask-getabstract/analytics/feedback`,
            method: 'POST',
            data: renderAskGetabstractAnalyticsFeedbackEventForm(askGetabstractAnalyticsFeedbackEventForm)
        });
}

