import { ensureNonNull } from "@utils/assertion";
import { type WarmupV2WidgetProps } from "@newgenerated/shared/schema";
import { serialize_WarmupV2WidgetProps, deserialize_WarmupV2WidgetProps } from "@newgenerated/shared/mapper";

export { type WarmupV2WidgetProps } from "@newgenerated/shared/schema";
export const parseWarmupV2WidgetProps = (data: unknown): WarmupV2WidgetProps => {
  return ensureNonNull(deserialize_WarmupV2WidgetProps(JSON.stringify(data)));
};
export const renderWarmupV2WidgetProps = (data: WarmupV2WidgetProps): any => {
  return JSON.parse(serialize_WarmupV2WidgetProps(data));
};
