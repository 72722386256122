import { ensureNonNull } from "@utils/assertion";
import { type VerifaiCreatorPageProps } from "@newgenerated/shared/schema";
import { serialize_VerifaiCreatorPageProps, deserialize_VerifaiCreatorPageProps } from "@newgenerated/shared/mapper";

export { type VerifaiCreatorPageProps } from "@newgenerated/shared/schema";
export const parseVerifaiCreatorPageProps = (data: unknown): VerifaiCreatorPageProps => {
  return ensureNonNull(deserialize_VerifaiCreatorPageProps(JSON.stringify(data)));
};
export const renderVerifaiCreatorPageProps = (data: VerifaiCreatorPageProps): any => {
  return JSON.parse(serialize_VerifaiCreatorPageProps(data));
};
