/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type FeatureType, parseFeatureType, renderFeatureType } from '../model/featureType';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* Return the accessible list of FeatureType for the given LoginContext.
*/
export async function getFeatures(): Promise<Array<FeatureType>> {
        return getHttpClient().request<Array<FeatureType>>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/features`,
            method: 'GET'
        })
        .then(response => {
                return response.data.map(value => parseFeatureType(value));
        });
}

