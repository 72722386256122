import { ensureNonNull } from "@utils/assertion";
import { type MIABPageProps } from "@newgenerated/shared/schema";
import { serialize_MIABPageProps, deserialize_MIABPageProps } from "@newgenerated/shared/mapper";

export { type MIABPageProps } from "@newgenerated/shared/schema";
export const parseMIABPageProps = (data: unknown): MIABPageProps => {
  return ensureNonNull(deserialize_MIABPageProps(JSON.stringify(data)));
};
export const renderMIABPageProps = (data: MIABPageProps): any => {
  return JSON.parse(serialize_MIABPageProps(data));
};
