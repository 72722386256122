import { assert } from "@utils/assertion";
import { asyncDataMissingMessage, internalData, syncDataMissingMessage } from "@utils/vue-migration/common/gaContext/gaContextInternals";
// eslint-disable-next-line no-restricted-imports
import type { Language } from "@newgenerated/shared/schema";

type TranslationSimpleFn = (key: string, list?: string[]) => string | undefined;
type TranslationWithDefaultMsgFn = (key: string, defaultMsg: string) => string;
type TranslationWithListWithDefaultMsgFn = (key: string, list: string[], defaultMsg: string) => string;

export type TranslationFn = TranslationSimpleFn & TranslationWithDefaultMsgFn & TranslationWithListWithDefaultMsgFn;

function interpolateString(text: string, list: string[]): string {
  return list.reduce((acc, curr, index) => {
    return acc.replace(new RegExp(`\\{${index}\\}`, "g"), curr);
  }, text);
}

export function getCurrentLanguage(): Language {
  const currentLanguage = internalData.sync.currentLanguage;
  assert(currentLanguage.state === "AVAILABLE", syncDataMissingMessage("currentLanguage"));
  return currentLanguage.value;
}

export function getTranslationFn(lang: Language): TranslationFn {
  const translationFn: TranslationSimpleFn = (key, list) => {
    const translation = internalData.async[lang];
    assert(translation.state === "AVAILABLE", asyncDataMissingMessage(`translation.${lang}`));

    const translatedText = translation.value.get(key);
    if (translatedText === undefined || list === undefined) {
      return translatedText;
    }
    return interpolateString(translatedText, list);
  };

  const translationWithDefaultMsgFn: TranslationWithDefaultMsgFn = (key, defaultMsg) => {
    const translation = internalData.async[lang];
    assert(translation.state === "AVAILABLE", asyncDataMissingMessage(`translation.${lang}`));

    const translatedText = translation.value.get(key);
    return translatedText ?? defaultMsg;
  };

  const translationWithListWithDefaultMsgFn: TranslationWithListWithDefaultMsgFn = (key, list, defaultMsg) => {
    const translation = internalData.async[lang];
    assert(translation.state === "AVAILABLE", asyncDataMissingMessage(`translation.${lang}`));

    const translatedText = translation.value.get(key);
    if (translatedText === undefined) {
      return defaultMsg;
    }
    return interpolateString(translatedText, list);
  };

  return ((key: string, ...args: [] | [string[]] | [string] | [string[], string]) => {
    if (args.length === 0) {
      return translationFn(key);
    } else if (args.length === 1 && typeof args[0] === "string") {
      return translationWithDefaultMsgFn(key, args[0]);
    } else if (args.length === 1) {
      return translationFn(key, args[0] as string[]);
    } else {
      return translationWithListWithDefaultMsgFn(key, args[0], args[1]);
    }
  }) as TranslationFn;
}
